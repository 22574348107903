var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content"},[_c('section',{staticStyle:{"margin-bottom":"1%"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.createPaymentNote}},[_c('i',{staticClass:"icon iconfont icon-new",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t('新增付款单'))+" ")])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-input',{attrs:{"allow-clear":"","placeholder":_vm.$t('请输入付款单号')},model:{value:(_vm.formData.noteSn),callback:function ($$v) {_vm.$set(_vm.formData, "noteSn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.noteSn"}})],1),_c('a-col',{attrs:{"span":6}},[_c('DateRangePicker',{attrs:{"id":"requestTime","needhms":false,"placeholder":_vm.$t(`请款时间`),"start-time":_vm.formData.applyTimeStart,"end-time":_vm.formData.applyTimeEnd},on:{"get_date":_vm.chooseRequestTime}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonQuerySelect',{attrs:{"api":"queryPurchaseSupplierList","params":{
            status: 1,
          },"placeholder":_vm.$t(`收款组织`),"code":_vm.formData.supplierCode,"name":_vm.formData.supplierName,"valueName":"supplier_code","labelName":"supplier_name"},on:{"update:code":function($event){return _vm.$set(_vm.formData, "supplierCode", $event)},"update:name":function($event){return _vm.$set(_vm.formData, "supplierName", $event)}}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonSelect',{attrs:{"placeholder":_vm.$t('单据类型'),"list":_vm.payment_note_type,"code":_vm.formData.orderType},on:{"update:code":function($event){return _vm.$set(_vm.formData, "orderType", $event)}}})],1)],1),_c('a-row',{staticClass:"mt10",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-button',{staticClass:"btnBgc",on:{"click":function($event){return _vm.searchHandle(1)}}},[_c('i',{staticClass:"iconfont",staticStyle:{"margin-right":"5px"}},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(`查询`))+" ")]),_c('a-button',{on:{"click":_vm.reset}},[_c('a-icon',{attrs:{"type":"redo"}}),_vm._v(" "+_vm._s(_vm.$t(`重置`))+" ")],1)],1)],1),_c('CommonTable',{ref:"commonTable",staticClass:"mt10",attrs:{"showSelect":true,"selectedRowKeys":_vm.selectedRowKeys,"selectedRows":_vm.selectedRows,"columns":_vm.paymentNoteColumns,"data-source":_vm.paymentNoteData,"statusList":_vm.payment_note_status,"rowKey":"id","scroll":{ x: 1500, y: _vm.scrollHeight },"loading":_vm.loading,"total":_vm.total},on:{"update:selectedRowKeys":function($event){_vm.selectedRowKeys=$event},"update:selected-row-keys":function($event){_vm.selectedRowKeys=$event},"update:selectedRows":function($event){_vm.selectedRows=$event},"update:selected-rows":function($event){_vm.selectedRows=$event},"update:columns":function($event){_vm.paymentNoteColumns=$event},"select":_vm.onSelectChange,"search":_vm.queryPaymentNoteList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex-ct"},[_c('OperateBtn',{attrs:{"api":"checkOrder","text":_vm.$t('审核'),"params":{
              docType: _vm.docType,
              idList: _vm.selectedRowKeys,
            },"disabled":_vm.selectedRows.length === 0},on:{"success":_vm.searchHandle}}),_c('OperateBtn',{staticClass:"ml10",attrs:{"disabled":!_vm.selectedRowKeys.length,"api":"payPaymentNote","text":_vm.$t('付款'),"params":{
              idList: _vm.selectedRowKeys,
            }},on:{"success":_vm.searchHandle}}),_c('VoucherBtn',{staticClass:"ml10",attrs:{"docType":_vm.docType,"selectedRows":_vm.selectedRows,"disabled":_vm.selectedRows.length === 0},on:{"close":_vm.searchHandle}})],1)]},proxy:true},{key:"paymentNoteSn",fn:function({ text, record }){return [_c('span',{staticClass:"text-link",on:{"click":function($event){return _vm.viewDetail(record)}}},[_vm._v(_vm._s(text))])]}},{key:"paymentAmount",fn:function({ text, record }){return [(text)?_c('span',[_vm._v(_vm._s(_vm._f("currencySymbol")(text,record.paymentCurrency)))]):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }